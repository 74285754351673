//core
import * as React from "react";
import arePropsEqual from "react-fast-compare";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import { FormControl, TextField, InputAdornment, Tooltip } from "@mui/material";

//icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// styles
import styles from './styles/styles.module.scss';

//types
interface IMuiTextField {
  name: string;
  id?: string;
  type?: React.InputHTMLAttributes<unknown>['type'];
  value?: unknown;
  label?: string;
  size?: any;
  multiline?: boolean,
  autoComplete?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  fieldClass?: string;
  helperText?: string,
  dataId?: string;
  InputProps?: any;
  error?: boolean | string;
  breakAllError?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onKeyDown?: (e: any) => void;
  tooltipText?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  autoFocus?: boolean;
  inputRef?: any | null;
  isRequired?: boolean;
}

const MuiTextField = (props:IMuiTextField & FieldHookConfig<string>) => {

  const [field, meta] = useField(props);
  const { handleBlur } = useFormikContext();

  const {
    size,
    type,
    name,
    id,
    value,
    label,
    autoComplete,
    placeholder,
    dataId,
    onChange,
    disabled,
    className,
    fieldClass = '',
    InputProps,
    multiline,
    helperText,
    breakAllError = false,
    error,
    onKeyDown,
    tooltipText = '',
    startAdornment = null,
    endAdornment = null,
    autoFocus = false,
    inputRef = null,
    isRequired = false,
  } = props;

  // input classes
  const cls = [styles.formField_input, className];

  if (breakAllError) {
    cls.push(styles.formField_breakAllError)
  }

  return (
    <div className={`${styles.formField} ${fieldClass}`}>
      <div className={styles.formField_input_wrapper}>
        <FormControl fullWidth>
          <TextField
            className={`${cls.join(' ')}`}
            { ...field }
            id={id}
            name={name}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            label={`${label}${isRequired ? '*' : ''}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={(e) => {
              handleBlur(e);
            }}
            onKeyDown={onKeyDown}
            type={type}
            size={size}
            data-id={dataId}
            disabled={disabled}
            multiline={multiline}
            // @ts-ignore
            error={meta.touched && meta.error && meta.error !== 'error' || error}
            helperText={meta.touched && meta.error && meta.error !== 'error' ? meta.error : ''}
            InputProps={{
              ...InputProps,
              startAdornment: !!startAdornment ? (<>
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              </>) : null,
              endAdornment: !!tooltipText || !!endAdornment ? (<>
                {!!endAdornment && (
                  <InputAdornment position="end">
                    {endAdornment}
                  </InputAdornment>
                )}
                {!!tooltipText && (
                  <InputAdornment position="end">
                    <Tooltip title={tooltipText}>
                      <InfoOutlinedIcon sx={{ cursor: 'pointer' }} fontSize="small" color="action" />
                    </Tooltip>
                  </InputAdornment>
                )}
              </>) : null,
            }}
            {...(!!inputRef ? {inputRef: inputRef} : {})}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default React.memo(MuiTextField, arePropsEqual);
