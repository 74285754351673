import React, { useCallback, useEffect, useState } from "react";
import arePropsEqual from "react-fast-compare";
import { useSelector } from "react-redux";
import { Theme } from "@mui/material/styles/createTheme";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
  amber,
  red,
  deepOrange,
  cyan,
  purple,
  grey,
  indigo,
  teal,
  orange, blue,
} from "@mui/material/colors";
import {
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

//icons
import EditIcon from '@mui/icons-material/Edit';

//
import { useStyles } from "./CommunicationStatus.styles"


//selectors
import {
  selectCreateCommunicationStatusData,
  selectProfileData
} from "../../../../../store/admin/affiliateProfile/selectors";

//hooks
import { useAffProfile } from "../../../../../store/admin/affiliateProfile/useAffProfile";
import { usePermissions } from "../../../../../store/common/configuration/usePermissions";

//components
import { itemListData } from "../../../../common/TableData";
import MuiModal from "../../../../UI/MuiModal";
import FormCommunication from "../../PartnerProfile/components/FormCommunication";
import {useParams} from "react-router-dom";


interface ICommunicationStatus {
  data: itemListData;
  fullWidth?: boolean;
  label?: string;
  extraSmall?: boolean;
}

const CommunicationStatus: React.FC<ICommunicationStatus> = ({
  data,
  fullWidth= false,
  label = '',
  extraSmall= false,
}): JSX.Element => {
  const theme: Theme = useTheme();
  const styles = useStyles();
  const {t} = useTranslation();
  const { hasPermissions } = usePermissions();

  const hasCommunicationCreate = hasPermissions(["api3.admin.affiliatescommunicationcontroller.create"]);

  const { createCommunicationStatus, clearCommunicationCreate, getProfile } = useAffProfile();

  const createData = useSelector(selectCreateCommunicationStatusData);

  const [value, setValue] = useState<string>(data.communication_status || '');
  const [dataCreate, setDateCreate] = useState<null | any>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!!data) {
      setDateCreate(
        {
          status: {
            value: '',
            is_disable: false,
          },
          date: {
            value: new Date(),
            is_disable: false,
          },
          notes: {
            value: '',
            is_disable: false,
          },
        }
      );
    }
  }, [data]);

  const handleToggleModal = useCallback((state: boolean) => {
    setShowModal(state);
  }, [setShowModal]);

  return (
    <>
      <Box className={styles.block}>
        {hasCommunicationCreate && (
          <IconButton
            sx={{ padding: '4px' }}
            size="small"
            onClick={() => handleToggleModal(true)}
            disabled={!hasCommunicationCreate}
          >
            <Tooltip title={t("admin.partners.profile.communication_history.change")} placement="top">
              <EditIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        )}
        
        {!!value ? (
          <>
            { value === 'no_contact'
              ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: grey[500]}} />
              : value === 'confirmation_letter'
                ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: amber[500]}} />
                : value === 'welcome_letter'
                  ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: deepOrange[500]}} />
                  : value === 'no_answer'
                    ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: indigo[500]}} />
                    : value === 'contacted_twice'
                      ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: teal[500]}} />
                      : value === 'condition_discussion'
                        ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: purple[500]}} />
                        : value === 'deal_agreed'
                          ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: theme.palette.success.main}} />
                          : value === 'ping_no_traffic'
                            ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: cyan[500]}} />
                            : value === 'return_later'
                              ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: orange[500]}} />
                              : value === 'stopped'
                                ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: red[500]}} />
                                : value === 'reminder_letter_sent'
                                  ? <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: blue[500]}}/>
                                  : <Chip size="small" label={t(`common.components.communication_status.${value}`)} color="primary" sx={{backgroundColor: theme.palette.success.main}} />
            }
          </>
        ) : '—'}
      </Box>

      <MuiModal
        width={472}
        open={showModal}
        closeOnOutside={false}
        setToggleModal={handleToggleModal}
      >
        <Typography variant="h6" sx={{ mb: 3.5 }}>
          {t("admin.partners.profile.communication_history.change")}
        </Typography>

        {!!dataCreate && (
          <FormCommunication
            data={dataCreate}
            onSubmit={createCommunicationStatus}
            clearData={createData}
            onClear={clearCommunicationCreate}
            setToggleModal={handleToggleModal}
            affId={data.id}
            affPlatformId={data.platform_id}
          />
        )}
      </MuiModal>
    </>
  );
};

export default React.memo(CommunicationStatus, arePropsEqual);
