export type ObjArrayItem = {
  id: string | number;
  [key: string]: any,
};

export type countryOption = {
  id: string
  label: string
}

export type countryOptionItem = {
  id: string;
  label: string;
  originalValue: string;
}

export const getIds = (arr: ObjArrayItem[]): string[] | number[] => {
  return arr.reduce((acc: string[] | number[], el) => {
    //@ts-ignore
    acc.push(el.id);
    return acc;
  }, []);
}

export const getCountriesByCode = (arr: string[], countries: countryOption[], t: (str: string) => void) => {
  return arr.reduce((acc: countryOption[], item: string) => {
    const country: countryOption[] = countries.filter(el  => el.id.toLowerCase() === item.toLowerCase());

    if (!!country.length) {
      acc.push({
        id: country[0].id,
        label: `${t(`common.components.creative.list_geolocales.${country[0].id}`)}`
      });
    }
    return acc;
  }, [])
}

export const getCountriesOptions = (countries: countryOption[], t: (str: string) => void) => {
  return countries.reduce((acc: countryOptionItem[], item: countryOption) => {
    acc.push({
      id: item.id,
      label: `${t(`common.components.creative.list_geolocales.${item.id}`)}`,
      originalValue: item.label,
    });

    return acc;
  }, [])
}


export const getFieldIdByLabel = (arr: ObjArrayItem[], value: string) => {
  const selected = arr.filter(item => item.label.toLowerCase() === value.toLowerCase())

  return selected[0].id;
}

export const getFieldByLabel = (selectedArr: string[], arr: any[]) => {
  return selectedArr.reduce((acc: any[], item: string) => {
    const arrIdLabel: any[] = arr.filter(el  => el.id.toLowerCase() === item.toLowerCase());

    if (!!arrIdLabel.length) {
      acc.push({
        id: arrIdLabel[0].id,
        label: arrIdLabel[0].id,
      });
    }

    return acc;
  }, [])
}

export const getFieldById = (selectedArr: any[], arr: any[]) => {
  return selectedArr.reduce((acc: any[], item: any) => {
    const arrIdLabel: any[] = arr.filter(el  => el.id === item.id);

    if (!!arrIdLabel.length) {
      acc.push(arrIdLabel[0]);
    }
    return acc;
  }, [])
}

export const getMirrorCountriesByCode = (arr: string[], countries: countryOption[], t: (str: string) => void) => {
  return arr.reduce((acc: countryOption[], item: any) => {
    const country: countryOption[] = countries.filter(el  => el.id.toLowerCase() === item.geo.id.toLowerCase());
    if (!!country.length) {
      acc.push({
        ...item,
        geo: {
          id: country[0].id,
          label: `${t(`common.components.creative.list_geolocales.${country[0].id}`)}`
        }
      });
    }
    return acc;
  }, [])
}