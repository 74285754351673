//core
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { endOfDay, startOfDay } from "date-fns";
import { useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";
import {
  Box,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//helpers
import {getDataIds, getGroupByOptions, getPeriod, PeriodTypes} from "../../../../../utils";

//constant
import { summaryPeriodOptions } from "../../../../../constant";
import { favoriteFilterKeys } from "../../../../constants";

//selectors
import {
  selectDealsData,
  selectOptionsData, selectPlatformsData
} from "../../../../../../../../store/affiliate/reports/summary_report_v2/selectors";

//hooks
import { useSummaryReport } from "../../../../../../../../store/affiliate/reports/summary_report_v2/useSummaryReport";

//context
import { GlobalContext } from "../../../../../../../../context/GlobalContext";

//components
import CSelect from "../../../../../../../UI/Fields/CSelect";
import CDateRangePicker from "../../../../../../../UI/Fields/CDateRangePicker";
import GroupByField, { IItemOption } from "../../../GroupByField";
import MuiAutocompleteSelectLazy from "../../../../../../../UI/Fields/MuiAutocompleteSelectLazy";
import { ISummaryReportFilterDataType } from "../../../../types";
import MuiAutocompleteSelect from "../../../../../../../UI/Fields/MuiAutocompleteSelect";
import MuiChipsInputField from "../../../../../../../UI/Fields/MuiChipsInput";
import {useRegistration} from "../../../../../../../../store/common/registration/useRegistration";


interface IVisibleFilterProps {
  filter: ISummaryReportFilterDataType;
  setUpdateFilter: Dispatch<SetStateAction<ISummaryReportFilterDataType>>;
  apiV2: boolean;
}

const VisibleFilter: React.FC<IVisibleFilterProps> = ({
  filter,
  setUpdateFilter,
  apiV2,
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const mobile = useMediaQuery('(max-width:767px)');

  const {
    getProducts,
    getTrackers,
    getCreatives,
    getBrands,
    getCurrencies,
    getDeviceTypes,
  } = useSummaryReport();
  const { countries } = useRegistration();

  const context = useContext(GlobalContext);

  const optionsData = useSelector(selectOptionsData);
  const dealsData = useSelector(selectDealsData);
  const platformsData = useSelector(selectPlatformsData);

  //state
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodTypes>(filter.period);
  const [startDate, setStartDate] = useState<Date | ''>(getPeriod(filter.period).start);
  const [endDate, setEndDate] = useState<Date | ''>(getPeriod(filter.period).end);

  const [registrationFrom, setRegistrationFrom] = useState<Date | ''>(!!filter.registrationFrom ? new Date(filter.registrationFrom) : '');
  const [registrationTo, setRegistrationTo] = useState<Date | ''>(!!filter.registrationTo ? new Date(filter.registrationTo) : '');

  const [firstDepositFrom, setFirstDepositFrom] = useState<Date | ''>(!!filter.firstDepositFrom ? new Date(filter.firstDepositFrom) : '');
  const [firstDepositTo, setFirstDepositTo] = useState<Date | ''>(!!filter.firstDepositTo ? new Date(filter.firstDepositTo) : '');

  const [cpaQualificationFrom, setCpaQualificationFrom] = useState<Date | ''>(!!filter.cpaQualificationFrom ? new Date(filter.cpaQualificationFrom) : '');
  const [cpaQualificationTo, setCpaQualificationTo] = useState<Date | ''>(!!filter.cpaQualificationTo ? new Date(filter.cpaQualificationTo) : '');

  const [groupBy, setGroupBy] = useState<IItemOption[]>(filter.group_by.fields);

  const [dataCountries, setDataCountries] = useState<any | null>(null);

  //favorite fields state
  const [favAffSRProductIds, setFavAffSRProductIds] = useLocalStorage<boolean>(favoriteFilterKeys.productIds, false);
  const [favAffSRPlatformIds, setFavAffSRPlatformIds] = useLocalStorage<boolean>(favoriteFilterKeys.platformIds, false);
  const [favAffSRTrackerIds, setFavAffSRTrackerIds] = useLocalStorage<boolean>(favoriteFilterKeys.trackerIds, false);
  const [favAffSRCreativeIds, setFavAffSRCreativeIds] = useLocalStorage<boolean>(favoriteFilterKeys.creativeIds, false);
  const [favAffSRBrands, setFavAffSRBrands] = useLocalStorage<boolean>(favoriteFilterKeys.brands, false);
  const [favAffSRCurrencies, setFavAffSRCurrencies] = useLocalStorage<boolean>(favoriteFilterKeys.currencies, false);
  const [favAffSRDealTypes, setFavAffSRDealTypes] = useLocalStorage<boolean>(favoriteFilterKeys.dealTypes, false);
  const [favAffSRAnid, setFavAffSRAnid] = useLocalStorage<boolean>(favoriteFilterKeys.anid, false);
  const [favAffSRPlayerId, setFavAffSRPlayerId] = useLocalStorage<boolean>(favoriteFilterKeys.playerId, false);
  const [favAffSRSource1, setFavAffSRSource1] = useLocalStorage<boolean>(favoriteFilterKeys.source1, false);
  const [favAffSRSource2, setFavAffSRSource2] = useLocalStorage<boolean>(favoriteFilterKeys.source2, false);
  const [favAffSRSource3, setFavAffSRSource3] = useLocalStorage<boolean>(favoriteFilterKeys.source3, false);
  const [favAffSRSource4, setFavAffSRSource4] = useLocalStorage<boolean>(favoriteFilterKeys.source4, false);
  const [favAffSRSource5, setFavAffSRSource5] = useLocalStorage<boolean>(favoriteFilterKeys.source5, false);
  const [favAffSRDeviceTypes, setFavAffSRDeviceTypes] = useLocalStorage<boolean>(favoriteFilterKeys.deviceTypes, false);
  const [favAffSRGeo, setFavAffSRGeo] = useLocalStorage<boolean>(favoriteFilterKeys.geo, false);

  useEffect(() => {
    if (!!selectedPeriod) {
      const period = getPeriod(selectedPeriod);

      if (!!period.start && !!period.end) {
        setStartDate(period.start);
        setEndDate(period.end);

        context.setAffSummaryReportFilter({
          ...context.affSummaryReportFilter,
          period: selectedPeriod,
          sent_from: format(startOfDay(new Date(period.start)), "yyyy-MM-dd HH:mm:ss"),
          sent_to: format(endOfDay(new Date(period.end)), "yyyy-MM-dd HH:mm:ss"),
        });
        setUpdateFilter((prevState: any) => {
          return {
            ...prevState,
            period: selectedPeriod,
            sent_from: format(startOfDay(new Date(period.start)), "yyyy-MM-dd HH:mm:ss"),
            sent_to: format(endOfDay(new Date(period.end)), "yyyy-MM-dd HH:mm:ss"),
          }
        })
      }
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (!!countries.data) {
      const data = countries.data.reduce((acc: any[], item: any) => {
        acc.push({
          id: item.code,
          value: item.code,
        });
        return acc;
      }, []);
      setDataCountries({
        list: data,
        total_entries: data.length,
      });
    }
  }, [countries, i18n.language]);

  useEffect(() => {
    setSelectedPeriod(filter.period);
  }, [filter.period]);

  useEffect(() => {
    setGroupBy(filter.group_by.fields);
  }, [filter.group_by.fields]);

  useEffect(() => {
    setUpdateFilter((prevState) => {
      return {
        ...prevState,
        group_by: {
          fields: groupBy,
        },
      }
    })
  }, [groupBy]);

  useEffect(() => {
    context.setAffSummaryReportFilter(filter);
  }, [filter]);

  useEffect(() => {
    setRegistrationFrom(!!filter.registrationFrom ? new Date(filter.registrationFrom) : '');
    setRegistrationTo(!!filter.registrationTo ? new Date(filter.registrationTo) : '');
  }, [filter.registrationFrom, filter.registrationTo]);

  useEffect(() => {
    setFirstDepositFrom(!!filter.firstDepositFrom ? new Date(filter.firstDepositFrom) : '');
    setFirstDepositTo(!!filter.firstDepositTo ? new Date(filter.firstDepositTo) : '');
  }, [filter.firstDepositFrom, filter.firstDepositTo]);

  useEffect(() => {
    setCpaQualificationFrom(!!filter.cpaQualificationFrom ? new Date(filter.cpaQualificationFrom) : '');
    setCpaQualificationTo(!!filter.cpaQualificationTo ? new Date(filter.cpaQualificationTo) : '');
  }, [filter.cpaQualificationFrom, filter.cpaQualificationTo]);


  const handleChangePeriodSelect = (event: SelectChangeEvent) => {
    //@ts-ignore
    const value: PeriodTypes = event.target.value;
    setSelectedPeriod(value);
  };

  const handleChangeDates = (start: Date, end: Date) => {
    const noEndDate = !end;

    setStartDate(start);
    setEndDate(noEndDate ? start : end);
    context.setAffSummaryReportFilter({
      ...context.affSummaryReportFilter,
      period: '',
      sent_from: format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss"),
      sent_to: format(endOfDay(new Date(noEndDate ? start : end)), "yyyy-MM-dd HH:mm:ss"),
    });
    setUpdateFilter((prevState: any) => {
      return {
        ...prevState,
        period: '',
        sent_from: format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss"),
        sent_to: format(endOfDay(new Date(noEndDate ? start : end)), "yyyy-MM-dd HH:mm:ss"),
      }
    });
  };

  const fieldPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const fieldTrackerIdsPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
      affiliateIds: null,
    }
  };

  const fieldCurrenciesPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
      brandCodes: !!filter.brands.length ? getDataIds(filter.brands) : null,
    }
  };

  const favoriteFields = !!dataCountries && !!optionsData && !mobile &&
  (favAffSRProductIds || favAffSRDealTypes || favAffSRTrackerIds ||
    favAffSRCreativeIds || favAffSRBrands || favAffSRCurrencies || favAffSRPlatformIds || favAffSRPlayerId ||
    favAffSRAnid || favAffSRSource1 || favAffSRSource2 || favAffSRSource3 || favAffSRSource4 || favAffSRSource5 || favAffSRDeviceTypes || favAffSRGeo)
    ? (
      <Box className={styles.favoriteFields}>
        {optionsData.available_filters.includes("productIds") && (<>
          {favAffSRProductIds && (<>
            <Box>
              <MuiAutocompleteSelectLazy
                keyValue="value"
                keyId="id"
                id="productIds"
                name="productIds"
                label={t(`admin.reports.filter.productIds`)}
                value={filter.productIds}
                multiple={true}
                showSelectAll={false}
                disabled={false}
                getOptions={getProducts}
                payloadData={fieldPayload()}
                labelWithId={false}
                onChange={setUpdateFilter}
                disableCloseOnSelect={true}
                maxHeightFieldScroll={32}
                showLabelCounter={true}
              />
            </Box>
          </>)}
        </>)}
        {optionsData.available_filters.includes("platformIds") && (<>
          {favAffSRPlatformIds && (
            <>
              {!!platformsData && platformsData.list.length > 1 && (
                <Box>
                  <MuiAutocompleteSelect
                    keyValue="value"
                    keyId="id"
                    id="platformIds"
                    name="platformIds"
                    label={t(`admin.reports.filter.platformIds`)}
                    value={filter.platformIds}
                    multiple={true}
                    showSelectAll={false}
                    disabled={false}
                    staticOptions={platformsData.list}
                    onChange={setUpdateFilter}
                    disableCloseOnSelect={true}
                    maxHeightFieldScroll={32}
                    showLabelCounter={true}
                  />
                </Box>
              )}
            </>
          )}
        </>)}
        {optionsData.available_filters.includes("trackerIds") && (<>
          {favAffSRTrackerIds && (
            <Box>
              <MuiAutocompleteSelectLazy
                keyValue="value"
                keyId="id"
                id="trackerIds"
                name="trackerIds"
                label={t(`admin.reports.filter.tracker_id`)}
                value={filter.trackerIds}
                multiple={true}
                showSelectAll={false}
                disabled={false}
                getOptions={getTrackers}
                payloadData={fieldTrackerIdsPayload()}
                labelWithId={true}
                onChange={setUpdateFilter}
                disableCloseOnSelect={true}
                maxHeightFieldScroll={32}
                showLabelCounter={true}
              />
            </Box>
          )}
        </>)}
        {optionsData.available_filters.includes("creativeIds") && (<>
          {favAffSRCreativeIds && (
            <Box>
              <MuiAutocompleteSelectLazy
                keyValue="value"
                keyId="id"
                id="creativeIds"
                name="creativeIds"
                label={t(`admin.reports.filter.creative_id`)}
                value={filter.creativeIds}
                multiple={true}
                showSelectAll={false}
                disabled={false}
                getOptions={getCreatives}
                payloadData={fieldPayload()}
                labelWithId={true}
                onChange={setUpdateFilter}
                disableCloseOnSelect={true}
                maxHeightFieldScroll={32}
                showLabelCounter={true}
              />
            </Box>
          )}
        </>)}

        {optionsData.available_filters.includes("geo") && (<>
          {favAffSRGeo && apiV2 && (
            <MuiAutocompleteSelect
              keyValue="value"
              keyId="id"
              id="geo"
              name="geo"
              label={t(`admin.reports.filter.geo`)}
              value={filter.geo}
              multiple={true}
              showSelectAll={false}
              disabled={false}
              staticOptions={dataCountries.list}
              onChange={setUpdateFilter}
              disableCloseOnSelect={true}
              maxHeightFieldScroll={32}
              showLabelCounter={true}
              prefix={`common.components.creative.list_geolocales.`}
            />
          )}
        </>)}

        {optionsData.available_filters.includes("brands") && (<>
          {favAffSRBrands && (
            <Box>
              <MuiAutocompleteSelectLazy
                keyValue="value"
                keyId="id"
                id="brands"
                name="brands"
                label={t(`admin.reports.filter.brands`)}
                value={filter.brands}
                multiple={true}
                showSelectAll={false}
                disabled={false}
                getOptions={getBrands}
                payloadData={fieldPayload()}
                labelWithId={true}
                onChange={setUpdateFilter}
                disableCloseOnSelect={true}
                maxHeightFieldScroll={32}
                showLabelCounter={true}
              />
            </Box>
          )}
        </>)}
        {optionsData.available_filters.includes("currencies") && (<>
          {favAffSRCurrencies && (
            <Box>
              <MuiAutocompleteSelectLazy
                keyValue="value"
                keyId="id"
                id="currencies"
                name="currencies"
                label={t(`admin.reports.filter.currencies`)}
                value={filter.currencies}
                multiple={true}
                showSelectAll={false}
                disabled={false}
                getOptions={getCurrencies}
                payloadData={fieldCurrenciesPayload()}
                labelWithId={false}
                onChange={setUpdateFilter}
                disableCloseOnSelect={true}
                maxHeightFieldScroll={32}
                showLabelCounter={true}
              />
            </Box>
          )}
        </>)}
        {optionsData.available_filters.includes("dealTypes") && (<>
          {favAffSRDealTypes && (
            <>
              {!!dealsData && dealsData.list.length > 0 && (
                <MuiAutocompleteSelect
                  keyValue="value"
                  keyId="id"
                  id="dealTypes"
                  name="dealTypes"
                  label={t(`admin.reports.filter.deal_type`)}
                  value={filter.dealTypes}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  staticOptions={dealsData.list}
                  onChange={setUpdateFilter}
                  disableCloseOnSelect={true}
                  maxHeightFieldScroll={32}
                  showLabelCounter={true}
                />
              )}
            </>
          )}
        </>)}
        {optionsData.available_filters.includes("anid") && (<>
          {favAffSRAnid && (
            <Box>
              <MuiChipsInputField
                id="anid"
                name="anid"
                label={t(`admin.reports.filter.anid`)}
                value={filter.anid}
                onChange={setUpdateFilter}
              />
            </Box>
          )}
        </>)}
        {optionsData.available_filters.includes("playerId") && (<>
          {favAffSRPlayerId && (
            <Box>
              <MuiChipsInputField
                id="playerId"
                name="playerId"
                label={t(`admin.reports.filter.playerId`)}
                value={filter.playerId}
                onChange={setUpdateFilter}
              />
            </Box>
          )}
        </>)}
        {optionsData.available_filters.includes("source1") && (<>
          {favAffSRSource1 && (
            <Box>
              <MuiChipsInputField
                id="source1"
                name="source1"
                label={t(`admin.reports.filter.source1`)}
                value={filter.source1}
                onChange={setUpdateFilter}
              />
            </Box>
          )}
        </>)}
        {optionsData.available_filters.includes("source2") && (<>
          {favAffSRSource2 && (
            <Box>
              <MuiChipsInputField
                id="source2"
                name="source2"
                label={t(`admin.reports.filter.source2`)}
                value={filter.source2}
                onChange={setUpdateFilter}
              />
            </Box>
          )}
        </>)}
        {optionsData.available_filters.includes("source3") && (<>
          {favAffSRSource3 && (
            <Box>
              <MuiChipsInputField
                id="source3"
                name="source3"
                label={t(`admin.reports.filter.source3`)}
                value={filter.source3}
                onChange={setUpdateFilter}
              />
            </Box>
          )}
        </>)}
        {optionsData.available_filters.includes("source4") && (<>
          {favAffSRSource4 && (
            <Box>
              <MuiChipsInputField
                id="source4"
                name="source4"
                label={t(`admin.reports.filter.source4`)}
                value={filter.source4}
                onChange={setUpdateFilter}
              />
            </Box>
          )}
        </>)}
        {optionsData.available_filters.includes("source5") && (<>
          {favAffSRSource5 && (
            <Box>
              <MuiChipsInputField
                id="source5"
                name="source5"
                label={t(`admin.reports.filter.source5`)}
                value={filter.source5}
                onChange={setUpdateFilter}
              />
            </Box>
          )}
        </>)}
        {optionsData.available_filters.includes("deviceTypes") && (<>
          {favAffSRDeviceTypes && (<>
            <Box>
              <MuiAutocompleteSelectLazy
                keyValue="value"
                keyId="id"
                id="deviceTypes"
                name="deviceTypes"
                label={t(`admin.reports.filter.deviceTypes`)}
                value={filter.deviceTypes}
                multiple={true}
                showSelectAll={false}
                disabled={false}
                getOptions={getDeviceTypes}
                payloadData={fieldPayload()}
                labelWithId={false}
                onChange={setUpdateFilter}
                disableCloseOnSelect={true}
                maxHeightFieldScroll={32}
                showLabelCounter={true}
              />
            </Box>
          </>)}
        </>)}
      </Box>
    ) : null;

  return (<>
    {!!optionsData && (
      <Box>
        <Box className={styles.wrapper}>
          <Box className={styles.box}>
            <CSelect
              name="period"
              label={t("admin.reports.filter.period")}
              value={selectedPeriod}
              options={summaryPeriodOptions}
              onChange={handleChangePeriodSelect}
              prefix="common.components.periods."
            />
          </Box>
          <Box className={styles.boxDate}>
            <CDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={handleChangeDates}
            />
          </Box>
        </Box>
        <Box className={styles.field}>
          <GroupByField
            name="groupBy"
            value={filter.group_by.fields}
            options={getGroupByOptions(optionsData.group_by)}
            setFilter={setGroupBy}
            prefix={"admin.reports.filter.group_by_select."}
          />
        </Box>
        {favoriteFields}
      </Box>
    )}
  </>);
};

export default VisibleFilter;
